import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {

  const checkMobile = () => {
    if ((typeof document === "undefined") || (typeof window === "undefined")) return false

    const touches = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    const mobileagent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const mobile_size = window.innerWidth < 820
    const tablet_size = window.innerWidth > 819

    if (touches) document.querySelector('html').classList.add('touchevents')

    return {touches: touches, mobile: mobile_size, tablet: touches && tablet_size, mobileagent: mobileagent}
  }

  const [isMobile, setIsMobile] = useState(null);
  const [isTablet, setIsTablet] = useState(null);
  const [isTouches, setIsTouches] = useState(null);
  const [freeze, setFreeze] = useState(false);

  useEffect(() => {
    const mob = checkMobile()

    setIsMobile(mob.mobile)
    setIsTablet(mob.tablet)
    setIsTouches(mob.touches)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.querySelector('html').style.overflow = freeze ? 'hidden' : ''
  }, [freeze]);

  return (
    <ThemeContext.Provider
      value={{
        isMobile, isTablet, isTouches,
        freeze, setFreeze
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };

